import { createContext, useContext, useState, useEffect } from 'react';
import { navigate } from '@reach/router';

import { authLogin, authLogout, authMe } from '../services/auth-service/auth.service';
import { onEventGtag } from '../hook/useGtag';
import { cleanupToken, EXPIRES, getUserToken, hasTokenExpired, saveToken, saveYuliCookie } from '../services/token-service/token.service';
import { useWLconfigContext } from './WLConfigContext';
import { PREVIEWS_MAX_COUNT, setBrowserCookie } from '../services/util-service/util.service';
import { getUserLocalData, removeUserLocalData, setUserLocalData } from '../services/users-service/users.service';
import { ROUTES } from '../services/navigation/navigation.routes';
import { showStreamBlockedPage } from '../services/navigation/navigation.service';
import { AGE_WARNING_OVERLAY } from '../components/AgeWarningOverlay/AgeWarningOverlay';
import LoadingMask from '../components/LoadingMask/LoadingMask';
import { MDM } from '../pages/MainDashBoard/MemberDealsPage/MemberDealsLoginModal';

const AuthContext = createContext();

export const contextLogout = {}; // force logout on all devices from BE side

const AuthProvider = ({ children }) => {
  const { wl_config: { preview_max_count } = {} } = useWLconfigContext();

  const [state, setState] = useState({
    userLoggedIn: false,
    showErrorLabel: false,
    showLoadingMask: false
  });
  const pathname = window.location.pathname;

  useEffect(() => {
    const hasFEdata = !!(getUserToken() && !hasTokenExpired());
    if (hasFEdata) {
      setState(prevState => ({ ...prevState, userLoggedIn: true }));
    }
  }, []);

  useEffect(() => {
    const singleSignOff = (type) => {
      const event = type === 'add' ? 'addEventListener' : 'removeEventListener';
      document[event]('visibilitychange', () => {
        if (!document.hidden) {
          const userDate = getUserLocalData();

          if (Object.keys(userDate).length === 0) {
            setState(prevState => ({ ...prevState, userLoggedIn: false }));
          }
        }
      });
    };

    singleSignOff('add');

    return () => singleSignOff('remove');
  }, []);

  const login = (password, username) => {
    if (password && username) {
      setState(prevState => ({ ...prevState, showLoadingMask: true }));

      authLogin(username, password)
        .then(resp => {
          const { expiresIn, jwt } = resp.data?.data || {};
          const userData = getUserLocalData();
          const nats_member_id = userData.memberid;

          onEventGtag({
            event: 'userLoggedIn',
            userType: 'member',
            userStatus: 'loggedin',
            loginURL: pathname,
            nats_member_id: nats_member_id,
            hostname: window.hostname,
            logged: 'Yes',
          });

          saveToken(jwt, expiresIn);
          // delete preview_max_count cookie when log in 
          setBrowserCookie(PREVIEWS_MAX_COUNT, preview_max_count);

          return resp;
        })
        .then(resp => {
          const { expiresIn } = resp.data?.data || {};

          authMe()
            .then(resp => {
              const data = resp.data?.data || {};
              setUserLocalData(data);
              data[EXPIRES] = expiresIn;
              saveYuliCookie(data);
              setState(prevState => ({
                ...prevState,
                userLoggedIn: true,
                showErrorLabel: false,
                showLoadingMask: false
              }));

              if (pathname.includes(ROUTES.signin)) {
                navigate(`/${ROUTES.myAccount}`);
              } else if (pathname.includes(ROUTES.ageVerificationLanding)) {
                window.location.href = '/';
              } else if (pathname.includes(ROUTES.safe)) {
                window.location.reload();
              }
            })
            .catch((err) => {
              setState(prevState => ({ ...prevState, showLoadingMask: false }));
              console.log(err);
            });
        })
        .catch(err => {
          setState(prevState => ({ ...prevState, showLoadingMask: false }));
          const { status } = err?.response || {};

          switch (status) {
            case 401: // 'Unauthorized login'
              setState(prevState => ({ ...prevState, showErrorLabel: true }));
              break;
            case 403: // "IP Limit Exceeded" 
              showStreamBlockedPage();
              break;
            default:
              break;
          }
        });
    }
  };

  const logout = (callback) => {
    setState(prevState => ({ ...prevState, showLoadingMask: true }));

    authLogout()
      .then(() => {
        removeUserLocalData();
        cleanupToken();
        const userData = getUserLocalData();
        const nats_member_id = userData.memberid;
        localStorage.removeItem(AGE_WARNING_OVERLAY);

        onEventGtag({
          event: 'userLoggedOut',
          userType: 'nonmember',
          userStatus: 'loggedout',
          logged: 'No',
          logoutURL: pathname,
          hostname: window.location.hostname.toLowerCase().trim(),
          nats_member_id: nats_member_id,
        });

        setState(prevState => ({ ...prevState, userLoggedIn: false }));

        navigate(`/${ROUTES.specialOffers}`);
        setState(prevState => ({ ...prevState, showLoadingMask: false }));

        if (callback) {
          callback();
        }
        localStorage.removeItem(MDM);
      })
      .catch(err => {
        setState(prevState => ({ ...prevState, showLoadingMask: false }));
        console.log(err);
      });
  };

  const removeErrorLabel = () => setState(prevState => ({ ...prevState, showErrorLabel: false }));

  contextLogout.logout = () => setState(prevState => ({ ...prevState, userLoggedIn: false }));

  return <AuthContext.Provider value={{
    ...state,
    login,
    logout,
    setState,
    removeErrorLabel
  }}>
    <LoadingMask showLoadingMask={state.showLoadingMask} />
    {children}
  </AuthContext.Provider>;
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('AuthContext must be used within a AuthProvider!');
  }
  return context;
};

export { AuthProvider, useAuthContext };
