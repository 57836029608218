import React, { useEffect } from 'react';

const SignInPage = () => {
  useEffect(() => {
    window.location.href = 'https://treasureislandmedia.com/members/login';
  }, []);

  return <h2 style={{ color: '#fff', textAlign: 'center', padding: '2em .5em' }}>Redirecting You To SignIn</h2>;
};

export default SignInPage;
